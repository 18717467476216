<template>
  <div>
    <div class="padding_top"></div>

    <b-container>
      <b-row>
        <b-col class="completed_tasks_row" cols="12" md="4">
          <b-row v-for="(task, index) in tasks" :key="index">
            <div
              @click="
                () => {
                  activeTaskIndex = index;
                }
              "
              class="completed_task_row"
            >
              <b>{{ task.data.task[0].title }}</b>
              <p>
                Lvl {{ task.data.task[0].level }} - Задание
                {{ task.data.task[0].num }}<br /><br />
              </p>

              <p>Изменено: {{ formatDate(task.updated_at) }}</p>

              <p>ID пользователя: {{ task.user_id }}</p>
            </div>
          </b-row>
        </b-col>

        <b-col class="completed_tasks_row" cols="12" md="7">
          <div
            v-if="activeTaskIndex != null && tasks[activeTaskIndex]"
            class="completed_task_row"
          >
            <p>{{ tasks[activeTaskIndex].data.task[0].title }}<br /></p>

            <p>ID пользователя: {{ tasks[activeTaskIndex].user_id }}</p>
            <p>Создано: {{ formatDate(tasks[activeTaskIndex].created_at) }}</p>
            <p>Изменено: {{ formatDate(tasks[activeTaskIndex].updated_at) }}</p>
            <!-- <p>{{ task.title }} </p> -->
            <!-- <p>Lvl {{ task.data.task[0].level }} - Задание {{ task.data.task[0].num }}<br/></p> -->

            <div
              v-for="(qa, index) in tasks[activeTaskIndex].data.answers"
              :key="index"
              class="questions_answers"
            >
              <p class="task_question">
                {{ qa.question[0].number }}. {{ qa.question[0].question }}
              </p>
              <p class="task_answer">{{ qa.answer }}</p>
            </div>

            <img
              v-if="tasks[activeTaskIndex].answer_file"
              class="completed_task_img"
              :src="
                $store.state.storageUrl +
                '/app/task_answer_files/' +
                tasks[activeTaskIndex].answer_file
              "
            />

            <b-row class="task-verify">
              <b-form-select
                value-field="id"
                text-field="title"
                v-model="tasks[activeTaskIndex].status_id"
                :options="statuses"
              ></b-form-select>

              <b-button @click="updateTask()" type="button">Сохранить</b-button>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapActions } from "vuex";

moment.locale("ru");

export default {
  name: "CompletedTasksView",
  data() {
    return {
      tasks: [],
      activeTaskIndex: null,

      statuses: [
        {
          id: 1,
          title: "Ожидает проверки",
        },
        {
          id: 2,
          title: "На доработке",
        },
        {
          id: 3,
          title: "Выполнено",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getUser"]),
    formatDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    async updateTask() {
      try {
        this.$store.commit("SET_LOADING", true);

        await axios.post(
          `admin/check_completed_task/${this.tasks[this.activeTaskIndex].id}`,
          {
            status_id: this.tasks[this.activeTaskIndex].status_id,
          }
        );

        this.tasks.splice(this.activeTaskIndex, 1);

        this.$bvToast.toast("Сохранено");

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        this.$bvToast.toast(`${e}`);
        console.error(e);
      }
    },
  },
  async mounted() {
    try {
      this.$store.commit("SET_LOADING", true);

      await this.getUser();

      let tasks = await axios.get("completed_tasks");
      this.tasks = tasks.data;

      this.$store.commit("SET_LOADING", false);
    } catch (e) {
      this.$store.commit("SET_LOADING", false);
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
.completed_task_row {
  background-color: rgba($color: #ccc, $alpha: 0.1);
  margin-bottom: 15px;
  width: 100%;

  border-radius: $border;
  padding: 10px 15px;

  font-size: 12px;
}

.completed_task_row p {
  margin: 0;
}

.completed_tasks_row {
  height: 80vh;
  overflow-y: scroll;
}

.questions_answers {
  padding-top: 10px;
  padding-bottom: 10px;
}
.task_question {
  margin: 0;
  color: rgba($color: #ccc, $alpha: 0.6);
  font-weight: 600;
}
.task_answer {
  font-size: 14px;
}

.completed_task_img {
  width: 100%;
  height: auto;
}

.task-verify {
  margin-top: 5vh;
}
</style>
